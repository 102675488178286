import React from 'react';

const SmallButton = (props) =>{
    return(
        <>
        <div>
        <button className='btn-customization sm-btn'>
           {props.Title}
        </button>
        </div>
        </>
    )
}

export default SmallButton;