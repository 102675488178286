import React from "react"
import MediumButton from "../buttons/MediumButton";

const CTA = (props) =>{
    return (
    <>
    <section className="container-fluid">
    <div className="row justify-content-center font-pri">
    <div className="col-11 col-md-8 text-center text-white rounded-5 bg-pri py-5">
    <h3 className="font-sub-heading fw-600 text-capitalize">
        {props.SubTitle}
        </h3>
        <h2 className="font-heading font-pri-bold text-uppercase">
            {props.Title}
        </h2>
        <div className="pt-3">
        <a  href="tel:(000)-000-0000" target="_blank">
            <MediumButton
            Title="Call Now"
            />
        </a>
        </div>
        </div>
            </div>
    </section>
    </>
    )
}

export default CTA;