import React from "react"
import {Instagram, Facebook, TelephoneFill,EnvelopeFill,GeoAltFill} from 'react-bootstrap-icons';

const ContactSection = (props) =>{
    return (
    <>
    <section className='container-fluid'>
                <div className='py-md-0 py-3'>
                <div className="py-md-5">
                    <div className='row justify-content-center text-center'>
                        <div className='col-12 col-xl-3 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <TelephoneFill className='col-pri' size={50} />
                                <div className='font-pri font-pri-bold font-sub-heading mt-3 mb-md-3'>
                                    <div className='fw-700' >Phone</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="font-pri font-para hover-sec-head" target="_blank" href="tel:(000)-000-0000">(000)-000-0000</a></div>
                            </div>
                            
                        </div>
                        <div className='col-12 col-xl-3 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <EnvelopeFill className='col-pri' size={50} />
                                <div className='font-pri font-pri-bold font-sub-heading mt-3 mb-md-3'>
                                    <div className='fw-700' >Email</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="font-pri font-para hover-sec-head"  target="_blank" href="mailto:info@example.com">info@example.com</a></div>
                            </div>
                            
                        </div>

                        <div className='col-12 col-xl-3 mb-3'>
                            <div className='my-2'>
                                <GeoAltFill className='col-pri' size={50} />
                                <div className='font-pri font-pri-bold font-sub-heading mt-3 mb-md-3'>
                                    <div className='fw-700' >Address</div>
                                </div>
                            </div>
                            <div className='my-2 px-md-4'>
                                    <div className=''><a className="font-pri font-para hover-sec-head"  target="_blank" href="https://www.google.com/maps/place/United+States/@37.6,-95.665,4z/data=!3m1!4b1!4m6!3m5!1s0x54eab584e432360b:0x1c3bb99243deb742!8m2!3d37.09024!4d-95.712891!16zL20vMDljN3cw?entry=ttu"> United States </a></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="container-fluid pt-md-4">
                <div className="row font-pri justify-content-center gy-5">             
                <div className="col-11 col-xl-5 pe-md-5">
                    <img className="w-100" src={props.Mobile} alt="" />
                </div>
                    <div className="col-12 col-xl-5 ps-xl-5 ms-xl-5 border-start border-2">
                    <div className="w-100 bg-pri py-4 px-4">
                    <h2 className="font-pri-bold font-sub-heading text-white mt-3">
            WORKING HOURS
        </h2>
		<table className="text-white border-white table-bordered table-sm mt-3 w-100">
			<tbody>
				<tr>
					<td className="fw-400" >Monday</td>
					<td>7:30AM - 4:00PM</td>
				</tr>
				<tr>
					<td className="fw-400" >Tuesday</td>
					<td>7:30AM - 4:00PM</td>
				</tr>
				<tr>
					<td className="fw-400" >Wednesday</td>
					<td>7:30AM - 11:30AM</td>
				</tr>
                <tr>
					<td className="fw-400" >Thursday</td>
					<td>7:30AM - 4:00PM</td>
				</tr>
                <tr>
					<td className="fw-400" >Friday</td>
					<td>7:30AM - 11:30AM</td>
				</tr>
                <tr>
					<td className="fw-400" >Sat - Sun</td>
					<td>Closed</td>
				</tr>
                <tr>
					<td className="fw-400" >Lunch Break</td>
					<td>12:00PM - 1:00PM</td>
				</tr>
			</tbody>
		</table>
        </div>
        <h3 className="mt-4 font-sub-heading font-pri-extrabold col-pri">
            Follow Us
            </h3>
            <div className="mt-1" >
            <a className="pe-3  hover-sec-head " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.instagram.com/" >
            <Instagram className=""/></a>
            
        <a className=" hover-sec-head " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.facebook.com/">
            <Facebook className=""/></a>
            </div>
        </div>
                </div>
            </section>

            <div className="container-fluid row justify-content-center pt-5">
            <div className="col-12 col-xl-11">
            <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25895663.485556163!2d-95.665!3d37.599999999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1686222431620!5m2!1sen!2s" width="600" height="380" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
            </div>
            </div>
    </>
    )
}

export default ContactSection;