
import React, { useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import ContactSection from '../components/contactus/ContactSection';

// Sources
import BgImage from "../assets/images/contactus/Banner1.png";
import Mobile from '../assets/images/contactus/Mobile1.png'

const ContactUs = () => {
  
  useEffect(()=>{
    window.scrollTo(0,0)
    window.document.title = "Contact Us"
  },[])

  return (
    <>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Title1="Contact Us"
        Title2="Empowering Care,"
        br={<br className="d-block d-md-none d-lg-block"/>}
        Title2_2="Healing Lives"
        Title3="Your Journey Begins Here" 
        />
        <div className='pt-4'>
    </div>
    <ContactSection
        Mobile={Mobile}
    />
    <Footer/>
    </>
  );
}

export default ContactUs;
