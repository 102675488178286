
import React, {useEffect} from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import BelowBanner from '../components/common/BelowBanner';
import OurValues from '../components/aboutus/OurValues'
import CTA from '../components/aboutus/CTA'
import TwoImgsTwoTexts from "../components/common/TwoImgsTwoTexts";
import MiddleBannerMap from "../components/common/MiddleBannerMap";
import OurInsurance from "../components/common/OurInsurance";
import TakeActionToday from "../components/common/TakeActionToday";

// Sources
import Img1 from "../assets/images/aboutus/Img1.png"
import Img2 from "../assets/images/aboutus/Img2.png"

import BgImage from "../assets/images/aboutus/Banner1.png";
import OurValuesImg from "../assets/images/aboutus/OurValues1.png"

const AboutUs = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
    window.document.title = "About Us"
  },[])

  return (
    <>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Title1="About Us"
        Title2="Empowering Health,"
        Title3="Transforming Lives" 
        />
        <div className='pt-4'>
    </div>
    <BelowBanner
    Title1="About"
    Title2="Us"
    Para="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laborum, aliquam, repellat at maxime aut dolorem, minima explicabo fugiat sunt error ipsa consectetur sit! Temporibus provident mollitia consectetur debitis placeat saepe quae, veritatis fugit facilis autem et explicabo dolorem impedit ipsa, libero tempora aliquam similique corrupti quas dolorum doloremque, illum optio nobis? Iusto labore, doloribus ducimus, maiores nobis explicabo numquam ipsum voluptates asperiores tenetur sit repudiandae recusandae? Dolor perferendis aliquid impedit, natus ullam unde numquam neque. Totam architecto libero pariatur eligendi!"
    />
    <div className='pt-4'>
    </div>
    <OurValues
    OurValuesImg={OurValuesImg}
    />
    <div className='pt-4'>
    </div>
    <CTA
    SubTitle="Let's build a healthier tomorrow together!"
    Title="Accepting new patients"
    />
    <div className='pt-4'>
    </div>
    <TwoImgsTwoTexts
        Img1={Img1}
        Img2={Img2}
        Title1_1="Our"
        Title1_2="Mission"
        Para1="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laborum, aliquam, repellat at maxime aut dolorem, minima explicabo fugiat sunt error ipsa consectetur sit! Temporibus provident mollitia consectetur debitis placeat saepe quae, veritatis fugit facilis autem et explicabo dolorem impedit ipsa, libero tempora aliquam similique corrupti quas dolorum doloremque, illum optio nobis? Iusto labore, doloribus ducimus, maiores nobis explicabo numquam ipsum voluptates asperiores tenetur sit repudiandae recusandae? Dolor perferendis aliquid impedit, natus ullam unde numquam neque. Totam architecto libero pariatur eligendi!"
        Title2_1="Our"
        Title2_2="Goal"
        Para2="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laborum, aliquam, repellat at maxime aut dolorem, minima explicabo fugiat sunt error ipsa consectetur sit! Temporibus provident mollitia consectetur debitis placeat saepe quae, veritatis fugit facilis autem et explicabo dolorem impedit ipsa, libero tempora aliquam similique corrupti quas dolorum doloremque, illum optio nobis? Iusto labore, doloribus ducimus, maiores nobis explicabo numquam ipsum voluptates asperiores tenetur sit repudiandae recusandae? Dolor perferendis aliquid impedit, natus ullam unde numquam neque. Totam architecto libero pariatur eligendi!"
        />
    <MiddleBannerMap/>
    <OurInsurance/>
    <div className='pt-5'>
    </div>
    <TakeActionToday/>
    <div className='pt-5'>
    </div>
    <Footer/>
    </>
  );
}

export default AboutUs;
