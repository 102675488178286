import React from "react"
import { Link } from "react-router-dom"
import SmallButton from "../buttons/SmallButton";


const HomeBanner = (props) =>{
    return (
    <>
        <section className="container-fluid px-0 bg-sample position-relative ">
        <img className="w-100" src={props.BgImage} alt="Banner" />
        <div className="home-banner-text font-pri">
        <h4 className="fw-600 ">
        Care For You,
        </h4>
       <h3 className="col-pri pb-md-3 font-pri-extrabold">
       Every Step <br className="d-block d-lg-none"/>Of The Way
       </h3> 
       <Link className="" to="/contact-us">
        <SmallButton
        Title="Get in touch"
        />
        </Link> 
        </div>
    </section>
    </>
    )
}

export default HomeBanner;