import React from "react"


const TwoImgsTwoTexts = (props) =>{
    return (
    <>
    <section className="container-fluid">
        
        {/* First Part */}
        <div className="row font-pri" style={{backgroundColor: "#f4e9e4"}}>
            <div className="col-12 col-lg-5 section-img-1" style={{backgroundImage:`url(${props.Img1})`}}>
            <img className='img-fluid invisible' alt='Img 1' src={props.Img1} />    
            </div>
            <div className="col-12 align-self-center col-lg-7 py-5 ps-xl-5"> 
            <div className=" px-xl-5 px-3">
            <h3 className="font-sub-heading fw-600 col-pri">
        {props.SubTitle1} 
        </h3>
            <h3 className="font-heading fw-600 text-capitalize">
                {props.Title1_1} <span className="font-pri-extrabold col-pri"> {props.Title1_2} </span>
            </h3>
            {props.hr1}
            <p className="font-para">
                {props.Para1}
            </p>
            </div>
            <div className="px-xl-5 px-3">
            <h3 className="font-heading fw-600 text-capitalize">
                {props.Title1_3} <span className="font-pri-extrabold col-pri"> {props.Title1_4} </span>
            </h3>
            {props.hr1_2}
            <p className="font-para">
                {props.Para1_2}
            </p>
            </div>
            </div>
        </div>

        {/* Second Part */}
        <div className="row font-pri" style={{backgroundColor: "#f6f6f6"}}>
        <div className="d-block d-lg-none col-12 col-lg-5 section-img-2" style={{backgroundImage:`url(${props.Img2})`}}>
            <img className='img-fluid invisible' alt='Img 2' src={props.Img2} />    
            </div>
            <div className="align-self-center col-12 col-lg-7 py-5 ps-xl-5" > 
            <div className=" px-xl-5 px-3">
            <h3 className="font-sub-heading fw-600 col-pri">
        {props.SubTitle2} 
        </h3>
            <h3 className="font-heading fw-600 text-capitalize">
                {props.Title2_1} <span className="font-pri-extrabold col-pri"> {props.Title2_2} </span>
            </h3>
            {props.hr2}
            <p className="font-para">
                {props.Para2}
            </p>
            </div>

            <div className=" px-xl-5  px-3">
            <h3 className="font-heading fw-600 text-capitalize">
                {props.Title2_3} <span className="font-pri-extrabold col-pri"> {props.Title2_4} </span>
            </h3>
            {props.hr2_2}
            <p className="font-para">
                {props.Para2_2}
            </p>
            </div>

            <div className=" px-xl-5  px-3">
            <h3 className="font-heading fw-600 text-capitalize">
                {props.Title2_5} <span className="font-pri-extrabold col-pri"> {props.Title2_6} </span>
            </h3>
            {props.hr2_3}
            <p className="font-para">
                {props.Para2_3}
            </p>
            </div>

            <div className=" px-xl-5  px-3">
            <h3 className="font-heading fw-600 text-capitalize">
                {props.Title2_7} <span className="font-pri-extrabold col-pri"> {props.Title2_8} </span>
            </h3>
            {props.hr2_4}
            <p className="font-para">
                {props.Para2_4}
            </p>
            </div>

            </div>
            <div className="d-none d-lg-block col-12 col-lg-5 section-img-2" style={{backgroundImage:`url(${props.Img2})`}}>
            <img className='img-fluid invisible' alt='Img 2' src={props.Img2} />    
            </div>
        </div>
    </section>
    </>
    )
}

export default TwoImgsTwoTexts;