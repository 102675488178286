import React from "react"
import {Link} from 'react-router-dom'
import MediumButton from "../buttons/MediumButton";

const MakeAnAppointment = () =>{
    return (
    <>
    <section className="container-fluid">
    <div className="row justify-content-center font-pri">
    <div className="col-md-8 col-11 text-center text-white rounded-5 bg-pri py-5">
    <h3 className="font-sub-heading fw-600 text-capitalize">
        Let's build a healthier tomorrow together!
        </h3>
        <h2 className="font-heading font-pri-bold text-uppercase">
            Make an appointment
        </h2>
        <div className="pt-3">
        <a className="d-block d-xl-inline pb-2 pb-xl-0 pe-xl-2" href="tel:(000)-000-0000" target="_blank">
            <MediumButton Title=" &nbsp; Call Now &nbsp; "/>
        </a>
        <Link className="ps-xl-2" to="/contact-us">
            <MediumButton Title="Contact Us"/>
        </Link>
        </div>
        </div>
            </div>
    </section>
    </>
    )
}

export default MakeAnAppointment;