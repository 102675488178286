import React from "react"
import FooterLogo from "../../assets/images/home/FooterLogo.png"
import {Link} from 'react-router-dom'
import {GeoAltFill, TelephoneFill, Instagram, Facebook, EnvelopeFill} from "react-bootstrap-icons"
import MediumButton from '../buttons/MediumButton'



const Footer = () =>{
    return (
        <>
<div className="container-fluid bg-footer">
  <div className="row justify-content-xl-center font-pri text-white ps-xl-5">
    <div className="col-12 col-xl-4 pe-xl-5 pt-4">
        <img className="footer-logo pb-4" src={FooterLogo} alt="Footer Logo"/>
        <div className="">
        <p className="">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis aut, eveniet error ea eligendi sed reiciendis. Doloremque incidunt sapiente corrupti error neque maiores porro quisquam vel eveniet, quos consectetur quod?
        </p>
        </div>
        <div className="" >
            <a className="pe-3  hover-sec " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.instagram.com/" >
            <Instagram className=""/></a>
            
        <a className=" hover-sec " style={{fontSize:"30px"}}  target="_blank" rel="noreferrer" href="https://www.facebook.com/">
            <Facebook className=""/></a>
            </div>
    </div>
    <div className="col-12 col-xl-3 ps-xl-5 pt-xl-4">
        {/* Quick Links */}
        <h2 className="pb-xl-2 footer-heading pt-4 mt-xl-2" >Quick Links
        <hr className="hr-footer col-pri"/>
        </h2>
        
    <div className="">
        <div className="pb-1"><Link className="hover-sec footer-text"  to="/about-us">About Us</Link> </div>
        <div className="pb-1"><Link className="hover-sec footer-text"  to="/providers">Providers</Link> </div>
        <div className="pb-1"><Link className="hover-sec footer-text"  to="/primary-care">Primary Care</Link> </div>
        <div className="pb-1"><Link className="hover-sec footer-text"  to="/urgent-care">Urgent Care</Link> </div>
        <div className="pb-1"><Link className="hover-sec footer-text"  to="/preventive-care">Preventive Care</Link> </div>
    </div>
    </div>
    {/* Get In Touch */}
    <div className="col-12 col-xl-4 pt-xl-4">
    <h2 className="pb-xl-2 footer-heading pt-4 mt-xl-2">Get In Touch
    <hr className="hr-footer col-pri"/>
    </h2>
    <div className="">
        <div className="pb-1"><a className="hover-sec footer-text"  target="_blank" rel="noreferrer" href="tel:(000)-000-0000" >
           <TelephoneFill className="me-2"/> (000)-000-0000 </a></div>
        <div className="pb-1"><a className="hover-sec footer-text"  target="_blank" rel="noreferrer" href="mailto:info@example.com" >
        <EnvelopeFill className="me-2"/> info@example.com </a> </div>
        <div className="pb-1"><a className="hover-sec footer-text"  target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/United+States/@37.6,-95.665,4z/data=!3m1!4b1!4m6!3m5!1s0x54eab584e432360b:0x1c3bb99243deb742!8m2!3d37.09024!4d-95.712891!16zL20vMDljN3cw?entry=ttu">
        <GeoAltFill className="me-2"/> United States </a> </div>
        </div>
        <div className="pt-4">
    <a href="tel:(000)-000-0000"  target="_blank" rel="noreferrer" > 
        <MediumButton
        Title= "(000)-000-0000"
        />
</a>
            </div>
        </div>
  </div>
</div>
{/* <section className="container-fluid">
    <span className=""> Copyright by IqbalSaeed 2023. Powered by <a href="https://simplesolutionz.org/" className="" target="_blank" rel="noreferrer">SimpleSolutionz</a> </span> 
    </section> */}
        </>
    )
}

export default Footer;