
import React, {useEffect} from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import HomeBanner from '../components/home/HomeBanner';
import OurServices from '../components/home/OurServices';
import DoctorBanner from '../components/common/DoctorBanner';
import OurTeam from '../components/home/OurTeam';
import PatientTestimonials from "../components/home/PatientTestimonials";
import MiddleBannerMap from "../components/common/MiddleBannerMap";
import OurInsurance from "../components/common/OurInsurance";
import TakeActionToday from "../components/common/TakeActionToday";
import { Link } from "react-router-dom"

import MediumButton from '../components/buttons/MediumButton';
import BelowBanner from '../components/common/BelowBanner';

import Img from '../assets/images/home/Img.png';

import BgImage from "../assets/images/home/Banner1.png";

const Home = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
    window.document.title = "Sample Website"
  },[])
  return (
    <>
    <Header/>
    <HomeBanner
    BgImage={BgImage}
    />
    <div className='pt-4'>
    </div>
    <BelowBanner
    Title1="Welcome to"
    Title2="Our Clinic"
    Para="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci natus velit, odit consectetur saepe ad, praesentium delectus porro quidem totam cumque veniam? Tenetur neque minima sed totam libero ipsum nobis."
    />
        <div className='pt-4'>
    </div>    
    <OurServices
        Title={<><h3 className="fw-600 font-heading text-center font-pri"> Our <span className="font-pri-extrabold col-pri"> Services </span> </h3> <hr className="hr-center col-sec"/></>}/>
    <div className='pt-5 mt-lg-3'>
    </div>
    <DoctorBanner 
            SubTitle="Dr. Lena Lena" 
            Title1="MEDICAL" 
            Title2="DIRECTOR"
            Para="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente magni nisi saepe similique fugiat corporis voluptas officia modi voluptatem quod. Id voluptas autem consectetur a earum optio necessitatibus officia repudiandae!"
            Img={Img}
            Button={<Link to="/providers">
              <MediumButton
              Title="Meet Our Providers"
              />
            </Link>}
            />
      <div className='pt-5 mt-lg-3'>
    </div>
    <OurTeam/>
    <div className='pt-5 mt-lg-3'>
    </div>
    <PatientTestimonials/>
    <div className='pt-5 mt-lg-3'>
    </div>
    <MiddleBannerMap/>
    <OurInsurance/>
    <div className='pt-5'>
    </div>
    <TakeActionToday/>
    <div className='pt-5'>
    </div>
    <Footer/>
    </>
  );
}

export default Home;
