import React from "react"
import Img1 from "../../assets/images/insurance/1.webp"
import Img2 from "../../assets/images/insurance/2.webp"
import Img3 from "../../assets/images/insurance/3.png"
import Img4 from "../../assets/images/insurance/4.webp"
import Img5 from "../../assets/images/insurance/5.webp"
import Img6 from "../../assets/images/insurance/6.webp"
import Img7 from "../../assets/images/insurance/7.png"
import Img8 from "../../assets/images/insurance/8.png"


const OurInsurance = () =>{
    return (
    <>
    <section className="container-fluid">
        <div className="row font-pri py-5" style={{backgroundColor: "#f6f6f6"}}>
            <div className="col-12 text-center" > 
            <h3 className="fw-600 font-heading">
                Our <span className="col-pri font-pri-extrabold"> Insurance </span>
            </h3>
            <hr className="hr-center col-sec"/>
            </div>
            <div className="col-12 pt-4">
                <div className="container-fluid row justify-content-md-center text-center insurance-logo">
                    <div className="col-6 col-lg-2">
                    <img src={Img1} alt="Img1" />
                    </div>
                    <div className="col-6 col-lg-2">
                    <img src={Img2} alt="Img2" />
                    </div>
                    <div className="col-6 col-lg-2">
                    <img src={Img3} alt="Img3" />
                    </div>
                    <div className="col-6 col-lg-2">
                    <img src={Img4} alt="Img4" />
                    </div>
                    <div className="d-none d-lg-block col-6 col-lg-2">
                    <img src={Img5} alt="Img5" />
                    </div>
                </div>

                <div className="container-fluid row justify-content-md-center text-center insurance-logo">
                    <div className="col-6 col-lg-2">
                    <img src={Img6} alt="Img1" />
                    </div>
                    <div className="col-6 col-lg-2">
                    <img src={Img7} alt="Img2" />
                    </div>
                    <div className="col-6 col-lg-2">
                    <img src={Img8} alt="Img3" />
                    </div>
                    <div className="d-block d-lg-none col-6 col-lg-2">
                    <img src={Img5} alt="Img5" />
                    </div>
                </div>
            </div>
            {/* <h3 className="text-center font-para fw-600 pt-4">
             * We accept all insurances, except Medicaid. 
            </h3> */}
        </div>
    </section>
    </>
    )
}

export default OurInsurance;