import React from "react"

const DoctorBanner = (props) =>{
    return (
        <>
        {/* 1st Section Content */}
        <div className="container-fluid py-5 shadow" style={{backgroundColor: "#f2f2f2"}}>
  <div className="row font-pri justify-content-center align-items-center">
  <div className="d-none d-xl-block col-11 col-xl-4 ">
  <img className="w-100" src={props.Img} alt="Dr" />
    </div>
    <div className="col-11 col-xl-5 ps-xl-5">
    <div className="pb-4">
        <h3 className="font-sub-heading fw-600">
        {props.SubTitle} 
        </h3>
        <h2 className="fw-600 font-heading">
        {props.Title1} <span className="col-pri font-pri-extrabold"> {props.Title2} </span>
        </h2>
        <hr className="hr-heading col-pri" style={{width:"220px"}} />
        <p className="font-para fw-400">
            {props.Para}
        </p>
    </div>
    {props.Button}
    </div>
    <div className="col-11 col-xl-5 d-block d-xl-none mt-5">
        <img className="w-100" src={props.Img} alt="Dr" />
    </div>
  </div>
</div>        
        </>

    )
}

export default DoctorBanner;