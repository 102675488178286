import React from "react"
import { Link } from "react-router-dom"
import PrimaryCare from "../../assets/images/providers/PrimaryCare.png"
import UrgentCare from "../../assets/images/providers/UrgentCare.png"
import PreventiveCare from "../../assets/images/providers/PreventiveCare.png"
import { ArrowRight } from "react-bootstrap-icons"

const OurServices2 = (props) =>{
    return (
    <>
    <section className="container-fluid">
    <h3 className="fw-600 font-heading text-center font-pri"> 
    Our <span className="font-pri-extrabold col-pri"> Services </span> 
    </h3> <hr className="hr-center col-sec"/>
        <div className="row gy-4 justify-content-center pt-2">
            <div className="col-11 col-md-8 col-xl-3 text-center rounded-0 pt-4 pb-2 me-xl-4">
            <Link className="hover-font" to="/primary-care">
                <div className="row bg-light shadow align-items-center">
                    <div className="col-6 px-0">
                <img className="w-100" src={PrimaryCare} alt="Primary Care" />
                    </div>
                    <div className="col-6">
                <h3 className="font-pri text-black font-para text-uppercase">
                    <div className="pb-2 fw-800"> Primary Care </div> 
                    <div className="text-capitalize pb-2"> Read More <ArrowRight/> </div> 
                </h3>
                    </div>
                </div>
                </Link>
            </div>

            <div className="col-11 col-md-8 col-xl-3 text-center rounded-0 pt-4 pb-2 me-xl-4">
            <Link className="hover-font" to="/urgent-care">
                <div className="row bg-light shadow align-items-center">
                    <div className="col-6 px-0">
                <img className="w-100" src={UrgentCare} alt="Urgent Care" />
                    </div>
                    <div className="col-6">
                <h3 className="font-pri text-black font-para text-uppercase">
                    <div className="pb-2 fw-800"> Urgent Care </div> 
                    <div className="text-capitalize pb-2"> Read More <ArrowRight/> </div> 
                </h3>
                    </div>
                </div>
                </Link>
            </div>

            <div className="col-11 col-md-8 col-xl-3 text-center rounded-0 pt-4 pb-2 me-xl-4">
            <Link className="hover-font" to="/preventive-care">
                <div className="row bg-light shadow align-items-center">
                    <div className="col-6 px-0">
                <img className="w-100" src={PreventiveCare} alt="Preventive Care" />
                    </div>
                    <div className="col-6">
                <h3 className="font-pri text-black font-para text-uppercase">
                    <div className="pb-2 fw-800"> Preventive Care </div> 
                    <div className="text-capitalize pb-2"> Read More <ArrowRight/> </div> 
                </h3>
                    </div>
                </div>
                </Link>
            </div>
        </div>
    </section>
    </>
    )
}

export default OurServices2;