
import React from 'react';
import {Routes, Route} from 'react-router-dom';

import Home from '../src/pages/Home';
import AboutUs from '../src/pages/AboutUs';
import Providers from '../src/pages/Providers';
import Services from '../src/pages/Services';
import PrimaryCare from '../src/pages/PrimaryCare';
import UrgentCare from '../src/pages/UrgentCare';
import PreventiveCare from '../src/pages/PreventiveCare';
import ContactUs from '../src/pages/ContactUs';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import '../src/assets/sass/style.scss';

const App = () => {
  return (
    <>
    {/* Color 1 */}
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/providers" element={<Providers/>} />
          <Route path="/our-services" element={<Services/>} />
          <Route path="/primary-care" element={<PrimaryCare/>} />
          <Route path="/urgent-care" element={<UrgentCare/>} />
          <Route path="/preventive-care" element={<PreventiveCare/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
        </Routes>
    </>
  );
}

export default App;
