
import React, {useEffect} from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import DoctorBanner from '../components/common/DoctorBanner';
import OurTeam from '../components/home/OurTeam';
import CTA from '../components/aboutus/CTA'
import OurServices2 from '../components/ourservices/OurServices2';
import MiddleBannerMap from "../components/common/MiddleBannerMap";
import OurInsurance from "../components/common/OurInsurance";
import TakeActionToday from "../components/common/TakeActionToday";

// Sources
import Img from '../assets/images/home/Img.png';

import BgImage from "../assets/images/providers/Banner1.png";

const Providers = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
    window.document.title = "Providers"
  },[])
  return (
    <>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Title1="Providers"
        Title2="We Are With You"
        Title3="Every Step Of The Way" 
        />
        <div className='pt-4'>
    </div>
    <DoctorBanner 
            SubTitle="Dr. Lena Lena" 
            Title1="MEDICAL" 
            Title2="DIRECTOR"
            Para="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente magni nisi saepe similique fugiat corporis voluptas officia modi voluptatem quod. Id voluptas autem consectetur a earum optio necessitatibus officia repudiandae!"
            Img={Img}
            />
    <div className='pt-5 mt-lg-3'>
    </div>
        <OurTeam/>
        <div className='pt-5 mt-lg-3'>
    </div>
    <CTA
    SubTitle="We are always in contact with our patients"
    Title="Contact our team members"
    />
        <div className='pt-5 mt-lg-3'>
    </div>
    <OurServices2/>
    <div className='pt-4'>
    </div>
    <MiddleBannerMap/>
    <OurInsurance/>
    <div className='pt-5'>
    </div>
    <TakeActionToday/>
    <div className='pt-5'>
    </div>
    <Footer/>
    </>
  );
}

export default Providers;
