
import React, {useEffect} from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import BelowBanner from '../components/common/BelowBanner';
import MakeAnAppointment from '../components/ourservices/MakeAnAppointment';
import OurServices2 from '../components/ourservices/OurServices2';
import TwoImgsTwoTexts from "../components/common/TwoImgsTwoTexts";
import MiddleBannerMap from "../components/common/MiddleBannerMap";
import OurInsurance from "../components/common/OurInsurance";
import TakeActionToday from "../components/common/TakeActionToday";

// Sources
import Img1 from "../assets/images/ourservices/primarycare/Img1.png"
import Img2 from "../assets/images/ourservices/primarycare/Img2.png"

import BgImage from "../assets/images/ourservices/primarycare/Banner1.png";

const PrimaryCare = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
    window.document.title = "PrimaryCare"
  },[])
  return (
    <>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Title1="Our Services"
        Title2="Primary Care"
        Title3="Your Health, Our Priority" 
        />
        <div className='pt-4'>
    </div>
    <BelowBanner
    Title1="Primary"
    Title2="Care"
    Para="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque nostrum consectetur officiis assumenda quod alias eius qui dolor illo nisi dicta possimus perspiciatis, ut numquam maiores repellat ipsum molestias nulla voluptates vel culpa cupiditate tempore vero. Dolor maxime incidunt iusto eius nesciunt repellat recusandae quasi ut ducimus suscipit eligendi illo aspernatur assumenda facere ipsum laboriosam, ad temporibus exercitationem inventore sequi minus veniam? Iste necessitatibus quaerat id labore qui voluptatibus veniam quod est eligendi earum! Dolorum magnam mollitia similique a hic!"
    />
        <div className='pt-4'>
    </div>
    <MakeAnAppointment/>
    <div className='pt-5 mt-lg-3'>
    </div>
    <TwoImgsTwoTexts
        Img1={Img1}
        Img2={Img2}
        Title1_1="Benefits of"
        Title1_2="Primary Care:"
        hr1={<hr className="hr-heading col-pri w-25" />}
        Para1="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Officiis earum voluptatem beatae veniam minima doloremque magnam, explicabo quod expedita molestiae laborum delectus sed. Unde sequi perspiciatis aspernatur officiis culpa, porro cumque illum doloremque voluptatem praesentium doloribus harum suscipit molestias sit quasi impedit, sint, recusandae facilis modi quae nesciunt beatae ab eum. Quis ipsum dignissimos beatae, sint quibusdam laborum voluptatibus minus, reprehenderit distinctio tenetur, eum porro maxime impedit soluta. Quasi eligendi ducimus quo consectetur beatae sed quod accusamus iusto explicabo. Est."
        Title2_1="Primary Care"
        Title2_2="Services Include:"
        hr2={<hr className="hr-heading col-pri w-25" />}
        Para2="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Officiis earum voluptatem beatae veniam minima doloremque magnam, explicabo quod expedita molestiae laborum delectus sed. Unde sequi perspiciatis aspernatur officiis culpa, porro cumque illum doloremque voluptatem praesentium doloribus harum suscipit molestias sit quasi impedit, sint, recusandae facilis modi quae nesciunt beatae ab eum. Quis ipsum dignissimos beatae, sint quibusdam laborum voluptatibus minus, reprehenderit distinctio tenetur, eum porro maxime impedit soluta. Quasi eligendi ducimus quo consectetur beatae sed quod accusamus iusto explicabo. Est."
        />
    <MiddleBannerMap/>
    <OurInsurance/>
    <div className='pt-5'>
    </div>
    <OurServices2/>
    <div className='pt-5'>
    </div>
    <TakeActionToday/>
    <div className='pt-4'>
    </div>
    <Footer/>
    </>
  );
}

export default PrimaryCare;
