import React from "react"
import Banner_mb from '../../assets/images/banner_mb/Banner1.png'
// import Banner_mb from '../../assets/images/banner_mb/Banner2.png'
// import Banner_mb from '../../assets/images/banner_mb/Banner3.png'
// import Banner_mb from '../../assets/images/banner_mb/Banner4.png'

const CommonBanner = (props) =>{
    return (
    <>
    <section className="container-fluid px-0 position-relative">
        <img className="w-100 d-none d-lg-block" src={props.BgImage} alt="Banner" />
        <img className="w-100 d-block d-lg-none mt-3"  src={Banner_mb} alt="Banner" />
        <div className="common-banner-text ms-md-5 mx-3">
        <h3 className="font-pri font-sub-banner text-white d-flex ">
            {props.Title1}
        </h3>
       <h3 className="font-pri font-banner text-capitalize font-pri-bold text-white"> 
       {props.Title2} {props.br} {props.Title2_2}<br/>
       <h3 className="font-pri font-sub-banner text-white"> {props.Title3} <br className="d-none d-md-block"/> {props.Title3_3}
       </h3>
       </h3> 
        </div>
    </section>
    </>
    )
}

export default CommonBanner;