import React from "react"


const BelowBanner = (props) =>{
    return (
    <>
    <section className="container-fluid">
        <div className="text-center font-pri px-2"> 
        <h3 className="fw-600 font-heading">
            {props.Title1} <span className="font-pri-extrabold col-pri"> {props.Title2} </span>
            </h3>
            <hr className="hr-center col-sec"/>
            <p className="font-para px-lg-5 ">
                {props.Para}
            </p>
        </div>
    </section>
    </>
    )
}

export default BelowBanner;