import React from "react"
import { Link } from "react-router-dom"
import MediumButton from "../buttons/MediumButton";


const TakeActionToday = () =>{
    return (
    <>
    <section className="container-fluid">
    <div className="row text-center font-pri">
    <div className="col-12">
        <div className="pb-4">
        <h3 className="text-capitalize fw-600 font-sub-heading">
        Discover a world of medical knowledge
        </h3>
        <h2 className="text-uppercase col-pri font-pri-extrabold font-heading">
            Take action today!
        </h2>
        </div>
        <a href="tel:(000)-000-0000" target="_blank">
            <MediumButton
            Title="Call Now"
            />
        </a>
        </div>
            </div>
    </section>
    </>
    )
}

export default TakeActionToday;