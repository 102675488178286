
import React, {useEffect} from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import BelowBanner from '../components/common/BelowBanner';
import MakeAnAppointment from '../components/ourservices/MakeAnAppointment';
import OurServices2 from '../components/ourservices/OurServices2';
import TwoImgsTwoTexts from "../components/common/TwoImgsTwoTexts";
import MiddleBannerMap from "../components/common/MiddleBannerMap";
import OurInsurance from "../components/common/OurInsurance";
import TakeActionToday from "../components/common/TakeActionToday";

import Img1 from "../assets/images/ourservices/urgentcare/Img1.png"
import Img2 from "../assets/images/ourservices/urgentcare/Img2.png"

import BgImage from "../assets/images/ourservices/urgentcare/Banner1.png";

const UrgentCare = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
    window.document.title = "Urgent Care"
  },[])
  return (
    <>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Title1="Our Services"
        Title2="Urgent Care"
        Title3="Bringing healthcare" 
        Title3_3="to your fingertips"
        />
        <div className='pt-4'>
    </div>
        <BelowBanner
    Title1="Urgent"
    Title2="Care"
    Para="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque nostrum consectetur officiis assumenda quod alias eius qui dolor illo nisi dicta possimus perspiciatis, ut numquam maiores repellat ipsum molestias nulla voluptates vel culpa cupiditate tempore vero. Dolor maxime incidunt iusto eius nesciunt repellat recusandae quasi ut ducimus suscipit eligendi illo aspernatur assumenda facere ipsum laboriosam, ad temporibus exercitationem inventore sequi minus veniam? Iste necessitatibus quaerat id labore qui voluptatibus veniam quod est eligendi earum! Dolorum magnam mollitia similique a hic!"
    />
        <div className='pt-4'>
    </div>
    <MakeAnAppointment/>
    <div className='pt-5 mt-lg-3'>
    </div>
    <OurServices2/>
    <div className='pt-4'>
    </div>
    <TwoImgsTwoTexts

        Img1={Img1}
        Img2={Img2}

        // Part 1 
        Title1_1="The benefits of"
        Title1_2="urgent care services:"
        hr1={<hr className="hr-heading col-pri w-25" />}
        Para1="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque nostrum consectetur officiis assumenda quod alias eius qui dolor illo nisi dicta possimus perspiciatis, ut numquam maiores repellat ipsum molestias nulla voluptates vel culpa cupiditate tempore vero. Dolor maxime incidunt iusto eius nesciunt repellat recusandae quasi ut ducimus suscipit eligendi illo aspernatur assumenda facere ipsum laboriosam, ad temporibus exercitationem inventore sequi minus veniam? Iste necessitatibus quaerat id labore qui voluptatibus veniam quod est eligendi earum! Dolorum magnam mollitia similique a hic!"

        // Part 2 
        Title2_1="No transportation"
        Title2_2="costs"
        hr2={<hr className="hr-heading col-pri w-25" />}
        Para2="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque nostrum consectetur officiis assumenda quod alias eius qui dolor illo nisi dicta possimus perspiciatis, ut numquam maiores repellat ipsum molestias nulla voluptates vel culpa cupiditate tempore vero. Dolor maxime incidunt iusto eius nesciunt repellat recusandae quasi ut ducimus suscipit eligendi illo aspernatur assumenda facere ipsum laboriosam, ad temporibus exercitationem inventore sequi minus veniam? Iste necessitatibus quaerat id labore qui voluptatibus veniam quod est eligendi earum! Dolorum magnam mollitia similique a hic!"

        // If the Part 2 has more than 1 Heading 
        // (Heading 2 of Part 2)
        // Title2_3="No taking"
        // Title2_4="time off of work"
        // hr2_2={<hr className="hr-heading col-pri w-25" />}
        // Para2_2="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque nostrum consectetur officiis assumenda quod alias eius qui dolor illo nisi dicta possimus perspiciatis, ut numquam maiores repellat ipsum molestias nulla voluptates vel culpa cupiditate tempore vero. Dolor maxime incidunt iusto eius nesciunt repellat recusandae quasi ut ducimus suscipit eligendi illo aspernatur assumenda facere ipsum laboriosam, ad temporibus exercitationem inventore sequi minus veniam? Iste necessitatibus quaerat id labore qui voluptatibus veniam quod est eligendi earum! Dolorum magnam mollitia similique a hic!"

        // (Heading 3 of Part 2)
        // Title2_5="No leaving"
        // Title2_6="kids behind"
        // hr2_3={<hr className="hr-heading col-pri w-25" />}
        // Para2_3="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque nostrum consectetur officiis assumenda quod alias eius qui dolor illo nisi dicta possimus perspiciatis, ut numquam maiores repellat ipsum molestias nulla voluptates vel culpa cupiditate tempore vero. Dolor maxime incidunt iusto eius nesciunt repellat recusandae quasi ut ducimus suscipit eligendi illo aspernatur assumenda facere ipsum laboriosam, ad temporibus exercitationem inventore sequi minus veniam? Iste necessitatibus quaerat id labore qui voluptatibus veniam quod est eligendi earum! Dolorum magnam mollitia similique a hic!"

        // (Heading 4 of Part 2)
        // Title2_7="The"
        // Title2_8="takeaway"
        // hr2_4={<hr className="hr-heading col-pri w-25" />}
        // Para2_4="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque nostrum consectetur officiis assumenda quod alias eius qui dolor illo nisi dicta possimus perspiciatis, ut numquam maiores repellat ipsum molestias nulla voluptates vel culpa cupiditate tempore vero. Dolor maxime incidunt iusto eius nesciunt repellat recusandae quasi ut ducimus suscipit eligendi illo aspernatur assumenda facere ipsum laboriosam, ad temporibus exercitationem inventore sequi minus veniam? Iste necessitatibus quaerat id labore qui voluptatibus veniam quod est eligendi earum! Dolorum magnam mollitia similique a hic!"
        />
    <MiddleBannerMap/>
    <OurInsurance/>
    <div className='pt-5'>
    </div>
    <OurServices2/>
    <div className='pt-5'>
    </div>
    <TakeActionToday/>
    <div className='pt-4'>
    </div>
    <Footer/>
    </>
  );
}

export default UrgentCare;
