import React from "react"
import { Link } from "react-router-dom"
import PrimaryCare from "../../assets/images/home/PrimaryCare.png"
import UrgentCare from "../../assets/images/home/UrgentCare.png"
import PreventiveCare from "../../assets/images/home/PreventiveCare.png"

const OurServices = (props) =>{
    return (
    <>
    <section className="container-fluid">
        {props.Title}
        <div className="row gy-4 justify-content-center pt-4">
            <div className="col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2 me-xl-4">
            <Link className="hover-font" to="/primary-care">
            <div className="pb-4">
                <img src={PrimaryCare} alt="Primary Care" />
                </div>
                <h3 className="font-pri text-black font-pri-bold text-uppercase">
                Primary Care
                </h3>
                <p className="font-pri text-black font-para px-4 ">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus neque tempore quis eaque, debitis vel quod omnis praesentium beatae magnam in nihil ab quasi dolor maiores sapiente dolore eius numquam. 
                </p>
                </Link>
            </div>

            <div className="col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2">
            <Link className="hover-font" to="/urgent-care">
            <div className="pb-4">
                <img src={UrgentCare} alt="Urgent Care" />
                </div>
                <h3 className="font-pri-hsb text-black font-sub-heading font-pri-bold text-uppercase">
                Urgent Care
                </h3>
                <p className="font-pri text-black font-para px-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus neque tempore quis eaque, debitis vel quod omnis praesentium beatae magnam in nihil ab quasi dolor maiores sapiente dolore eius numquam. 
                </p>
            </Link>
            </div>

            <div className="col-11 col-md-8 col-xl-3 text-center bg-white  rounded-5 shadow pt-4 pb-2 ms-xl-4">
            <Link className="hover-font" to="/preventive-care">
            <div className="pb-4">
                <img src={PreventiveCare} alt="Preventive Care" />
                </div>
                <h3 className="font-pri-hsb text-black font-sub-heading font-pri-bold text-uppercase">
                Preventive Care
                </h3>
                <p className="font-pri text-black font-para px-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus neque tempore quis eaque, debitis vel quod omnis praesentium beatae magnam in nihil ab quasi dolor maiores sapiente dolore eius numquam. 
                </p>
            </Link>
            </div>
        </div>
    </section>
    </>
    )
}

export default OurServices;