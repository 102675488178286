
import React from 'react';
import { Link } from "react-router-dom"
import {GeoAltFill, TelephoneFill } from "react-bootstrap-icons"
import SmallerButton from '../buttons/SmallerButton'

import HeaderLogo from "../../assets/images/home/HeaderLogo1.png"

const Header = () => {
  return (
    <>
    {/* Upper Header */}
<div className='container-fluid font-pri px-lg-5'>
    <div className='row col-font py-1'>
    <div className='col-12 col-lg-4 d-none d-lg-block fw-600 font-upper-heading'>
We Are Accepting New Patients 
</div>

<div className='col-12 col-lg-8 text-lg-end'>
    {/* Phone Number */}
    <a href="tel:(000)-000-0000" className="  hover-sec-head "  target="_blank" rel="noreferrer" > 
    <TelephoneFill className='col-sec me-2'/>
        <span className="fst-normal  font-upper-heading"> (000)-000-0000 </span>
    </a> 

    {/* Br for Mobile  */}
    <br className='d-block d-lg-none' />
    {/* Address */}
    <a href="https://www.google.com/maps/place/United+States/@37.6,-95.665,4z/data=!3m1!4b1!4m6!3m5!1s0x54eab584e432360b:0x1c3bb99243deb742!8m2!3d37.09024!4d-95.712891!16zL20vMDljN3cw?entry=ttu" className="ps-xl-5  hover-sec-head" target="_blank" rel="noreferrer"> 
    <GeoAltFill className='col-sec me-1'/>
        <span className="fst-normal  font-upper-heading"> United States </span>
    </a>
</div>
    </div>
</div>

{/* Header Starts From Here */}
<div className='container-fluid bg-pri px-xl-5'>
<nav className="navbar navbar-expand-lg py-0">
  <Link className="navbar-brand bg-white px-2" to="/"> 
  <img className="header-logo" alt="logo " id="logo" src={HeaderLogo}/> </Link>
    <button className="navbar-toggler bg-white border-0 rounded-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item ">
          <Link  className="nav-link" aria-current="page" to="/about-us">About Us</Link>
        </li>
        <li className="nav-item ">
          <Link  className="nav-link" aria-current="page" to="/providers">Providers</Link>
        </li>
        <li className="nav-item right-menu">
          <Link  className="nav-link dropdown-toggle" to="/our-services" role="button">
            Services
          </Link>
          <ul className="dropdown-menu">
          <li> <Link className="dropdown-item hover-sec-head bg-white" to="/primary-care" >Primary Care</Link></li>
            <li><Link className="dropdown-item hover-sec-head bg-white" to="/urgent-care" >Urgent Care</Link></li>
            <li><Link className="dropdown-item hover-sec-head bg-white" to="/preventive-care" >Preventive Care</Link></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/contact-us"> Contact Us </Link>
        </li>
        {/* <li className="nav-item right-menu">
          <Link  className="nav-link dropdown-toggle" to="" role="button" aria-expanded="false">
            Useful Links
          </Link>
          <ul className="dropdown-menu ">
            <a className="dropdown-item  " target="_blank" rel="noreferrer" href="https://www.cdc.gov/"><li>CDC</li></a>
            <li><a className="dropdown-item " target="_blank" rel="noreferrer" href="https://www.alabamapublichealth.gov/index.html">ADPH</a></li>
            <li><a className="dropdown-item " target="_blank" rel="noreferrer" href="https://www.nih.gov/">NIH</a></li>
            <li><a className="dropdown-item " target="_blank" rel="noreferrer" href="https://www.ama-assn.org/">AMA</a></li>
          </ul>
        </li> */}
        <li className="mx-lg-4 border-white border-end">
        </li>
        <li className="nav-item py-2 py-lg-0">
            <a href="tel:(000)-000-0000"  target="_blank" rel="noreferrer" > 
            <SmallerButton
            Title= "Call Now"
            />
            </a>
        </li>
      </ul>
    </div>
</nav>
</div>
    </>
  );
}

export default Header;
