import React from "react"
import { Link } from "react-router-dom"
import Img1 from "../../assets/images/ourteam/Img1.png"
import Img2 from "../../assets/images/ourteam/Img2.png"
import Img3 from "../../assets/images/ourteam/Img3.png"

const OurServices = () =>{
    return (
    <>
    <section className="container-fluid">
    <h3 className="fw-600 font-heading text-center font-pri">
            Our <span className="font-pri-extrabold col-pri"> Team </span>
            </h3>
            <hr className="hr-center col-sec"/>
        <div className="row gy-4 justify-content-center pt-4">
            <div className="col-11 col-md-8 col-xl-3 text-center bg-light  rounded-5 shadow py-5 me-xl-4">
            <Link className="hover-font" to="/providers">
            <div className="pb-4">
                <img src={Img1} alt="Providers" />
                </div>
                <h3 className="font-pri-hsb text-black font-sub-heading font-pri-bold">
                Dr. Rokus Igneas
                </h3>
                <p className="text-black fw-400 font-pri font-para px-4">
                    Cardiologist
                </p>
                <hr className="hr-center col-pri" style={{width:"112px"}}/>
                </Link>
            </div>

            <div className="col-11 col-md-8 col-xl-3 text-center py-5 bg-light rounded-5 shadow ">
            <Link className="hover-font" to="/providers">
            <div className="pb-4">
                <img src={Img2} alt="Providers" />
                </div>
                <h3 className="font-pri-hsb text-black font-sub-heading font-pri-bold">
                Dr. Lena James
                </h3>
                <p className="text-black fw-400 font-pri font-para px-4">
                Nurse Practitioner
                </p>
                <hr className="hr-center col-pri" style={{width:"112px"}}/>
            </Link>
            </div>

            <div className="col-11 col-md-8 col-xl-3 text-center py-5 bg-light  rounded-5 shadow ms-xl-4">
            <Link className="hover-font" to="/providers">
            <div className="pb-4">
                <img src={Img3} alt="Providers" />
                </div>
                <h3 className="font-pri-hsb text-black font-sub-heading font-pri-bold">
                Dr. Kumar Raj
                </h3>
                <p className="text-black fw-400 font-pri font-para px-4">
                Physiotherapist
                </p>
                <hr className="hr-center col-pri" style={{width:"112px"}}/>
            </Link>
            </div>
        </div>
    </section>
    </>
    )
}

export default OurServices;