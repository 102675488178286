
import React, {useEffect} from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CommonBanner from '../components/common/CommonBanner';
import BelowBanner from '../components/common/BelowBanner';
import OurServices from '../components/home/OurServices';
import MiddleBannerMap from "../components/common/MiddleBannerMap";
import OurInsurance from "../components/common/OurInsurance";
import TakeActionToday from "../components/common/TakeActionToday";

// Sources
import BgImage from "../assets/images/ourservices/main/Banner1.png";
import Banner_mb from "../assets/images/aboutus/Banner1.png";

const Services = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
    window.document.title = "Services"
  },[])
  return (
    <>
    <Header/>
    <CommonBanner
        BgImage={BgImage}
        Banner_mb={Banner_mb}
        Title1="Our Services "
        Title2="Your Health,"
        br={<br className="d-none d-md-block"/>}
        Title2_2="Our Expertise"
        Title3="Unparalleled Services Await" 
        />
        <div className='pt-4'>
    </div>
    <BelowBanner
    Title1="Our"
    Title2="Services"
    Para="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque nostrum consectetur officiis assumenda quod alias eius qui dolor illo nisi dicta possimus perspiciatis, ut numquam maiores repellat ipsum molestias nulla voluptates vel culpa cupiditate tempore vero. Dolor maxime incidunt iusto eius nesciunt repellat recusandae quasi ut ducimus suscipit eligendi illo aspernatur assumenda facere ipsum laboriosam, ad temporibus exercitationem inventore sequi minus veniam? Iste necessitatibus quaerat id labore qui voluptatibus veniam quod est eligendi earum! Dolorum magnam mollitia similique a hic!"
    />
    <div className='pt-4'>
    </div> 
    <OurServices/>
    <div className='pt-5 mt-lg-3'>
    </div>
    <MiddleBannerMap/>
    <OurInsurance/>
    <div className='pt-5'>
    </div>
    <TakeActionToday/>
    <div className='pt-5'>
    </div>
    <Footer/>
    </>
  );
}

export default Services;
