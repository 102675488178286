import React from "react"
import { Quote } from "react-bootstrap-icons";


const PatientTestimonials = () =>{
    return (
    <>
        <section className="container-fluid">
    <div className="row font-pri">
    <div className="col-12 text-center" > 
            <h3 className="fw-600 font-heading">
            Patient <span className="font-pri-extrabold col-pri"> Testimonials </span>
            </h3>
            <hr className="hr-center col-sec"/>
            </div>
            </div>
    </section>

    <section className="container-fluid">
        <div className="row font-pri pt-4 justify-content-center gy-4">
            {/* Testimonial 1 */}
            <div className="col-11 col-md-8 col-xl-4 text-center bg-white shadow p-4 rounded-5 me-xl-4">
                <div className="">
                    <Quote className="font-quote"/>
                </div>
                <h3 className="font-pri-bold font-sub-heading">
                JENNIFER SMARTT
                </h3>
                <p className="font-para">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda iste deleniti dolorum, nesciunt minima numquam deserunt unde ullam consequatur obcaecati cumque.
                </p>
            </div>
            {/* Testimonial 2 */}
            <div className="col-11 col-md-8 col-xl-4 text-center bg-white shadow p-4 rounded-5 me-xl-4">
                <div className="">
                    <Quote className="font-quote"/>
                </div>
                <h3 className="font-pri-bold font-sub-heading">
                JENNIFER SMARTT
                </h3>
                <p className="font-para ">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda iste deleniti dolorum, nesciunt minima numquam deserunt unde ullam consequatur obcaecati cumque.
                </p>
            </div>
        </div>
        </section>
    </>
    )
}

export default PatientTestimonials;